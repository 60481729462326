import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import CourseList from '../components/CoursesPage/CourseList'
import Dashboard from '../components/DashboardPage/Dashboard'
import Error from '../components/OtherPages/Error'
import Login from '../components/OtherPages/Login'
import { Layout } from '../components/Theme/Layout'
import DeferredReport from '../components/DeferredReport/DeferredReport'

const CourseTypes = lazy(() =>
  import('../components/CoursesPage/CourseTypes')
)

const WebinarAdd = lazy(() => import('../components/WebinarsPages/WebinarAdd'))
const WebinarUser = lazy(() =>
  import('../components/WebinarsPages/WebinarUser')
)
const WebinarList = lazy(() =>
  import('../components/WebinarsPages/WebinarList')
)
const WebinarSEO = lazy(() => import('../components/WebinarsPages/WebinarSEO'))
const WebinarVideo = lazy(() =>
  import('../components/WebinarsPages/WebinarVideo')
)
const WebinarIosTier = lazy(() =>
  import('../components/WebinarsPages/WebinarIosTier')
)
const WebinarUpdateLink = lazy(() =>
  import('../components/WebinarsPages/WebinarUpdateLink')
)
const WebinarZoomAttendee = lazy(() =>
  import('../components/WebinarsPages/WebinarZoomAttendee')
)

const WebinarTag = lazy(() => import('../components/WebinarsPages/WebinarTag'))
const WebinarCategoryEdit = lazy(() => import('../components/WebinarsPages/WebinarCategoryEdit'))

const NPSScore = lazy(() => import('../components/NPSPages/NPSScore'))
const NPSFeedback = lazy(() => import('../components/NPSPages/NPSFeedback'))
const UsersList = lazy(() => import('../components/UsersPage/UsersList'))
const UserView = lazy(() => import('../components/UsersPage/UserView'))
const UserEdit = lazy(() => import('../components/UsersPage/UserEdit'))
const UserItem = lazy(() => import('../components/UsersPage/UserItem'))
const UserLearningList = lazy(() => import('../components/UsersPage/UserLearningList'))

const CourseDeliveryModeList = lazy(() =>
  import('../components/CourseDeliveryModePages/CourseDeliveryModeList')
)
const CourseDeliveryModeSEO = lazy(() =>
  import('../components/CourseDeliveryModePages/CourseDeliveryModesSEO')
)
const CourseDeliveryModeAdd = lazy(() =>
  import('../components/CourseDeliveryModePages/CourseDeliveryModeAdd')
)

const CourseSubjectList = lazy(() =>
  import('../components/CourseSubjectsPages/CourseSubjectList')
)
const CourseSubjectAdd = lazy(() =>
  import('../components/CourseSubjectsPages/CourseSubjectAdd')
)
const CourseSubjectSEO = lazy(() =>
  import('../components/CourseSubjectsPages/CourseSubjectSEO')
)

const CourseLevelList = lazy(() =>
  import('../components/CourseLevelPages/CourseLevelList')
)
const CourseLevelAdd = lazy(() =>
  import('../components/CourseLevelPages/CourseLevelAdd')
)
const CourseLevelSEO = lazy(() =>
  import('../components/CourseLevelPages/CourseLevelSEO')
)

const CourseLanguageList = lazy(() =>
  import('../components/CourseLanguagePages/CourseLanguageList')
)
const CourseLanguageAdd = lazy(() =>
  import('../components/CourseLanguagePages/CourseLanguageAdd')
)
const CourseLanguageSEO = lazy(() =>
  import('../components/CourseLanguagePages/CourseLanguageSEO')
)

const TaxList = lazy(() => import('../components/TaxPages/TaxList'))
const TaxAdd = lazy(() => import('../components/TaxPages/TaxAdd'))

const CountryList = lazy(() => import('../components/CountryPages/CountryList'))
const CountryAdd = lazy(() => import('../components/CountryPages/CountryAdd'))

const StateList = lazy(() => import('../components/StatePages/StateList'))
const StateAdd = lazy(() => import('../components/StatePages/StateAdd'))

const CityList = lazy(() => import('../components/CityPages/CityList'))
const CityAdd = lazy(() => import('../components/CityPages/CityAdd'))

const ModuleList = lazy(() => import('../components/ModulePages/ModuleList'))
const ModuleAdd = lazy(() => import('../components/ModulePages/ModuleAdd'))

const ModulePermissionList = lazy(() =>
  import('../components/ModulePermissionPages/ModulePermissionList')
)
const ModulePermissionEdit = lazy(() =>
  import('../components/ModulePermissionPages/ModulePermissionEdit')
)
const ModulePermissionAdd = lazy(() =>
  import('../components/ModulePermissionPages/ModulePermissionAdd')
)

const ComboList = lazy(() => import('../components/ComboPages/ComboList'))
const ComboAdd = lazy(() => import('../components/ComboPages/ComboAdd'))
const ComboIosTier = lazy(() => import('../components/ComboPages/ComboIosTier'))
const ComboSEO = lazy(() => import('../components/ComboPages/ComboSEO'))
const ComboCategoryEdit = lazy(() => import('../components/ComboPages/ComboCategoryEdit'))
const ComboBatch = lazy(() => import('../components/ComboPages/ComboBatch'))
const ComboBatchAdd = lazy(() => import('../components/ComboPages/ComboBatchAdd'))
const ComboItem = lazy(() => import('../components/ComboPages/ComboItem'))

const CouponTypes = lazy(() => import('../components/CouponPages/CouponTypes'))
const CouponAdd = lazy(() => import('../components/CouponPages/CouponAdd'))
const CouponList = lazy(() => import('../components/CouponPages/CouponList'))
const CouponView = lazy(() => import('../components/CouponPages/CouponView'))

const ContentList = lazy(() => import('../components/ContentPages/ContentList'))
const ContentAdd = lazy(() => import('../components/ContentPages/ContentAdd'))
const ContentSEO = lazy(() => import('../components/ContentPages/ContentSEO'))

const EmailList = lazy(() => import('../components/EmailPages/EmailList'))
const EmailAdd = lazy(() => import('../components/EmailPages/EmailAdd'))

const GameList = lazy(() => import('../components/GamePages/GameList'))
const GameAdd = lazy(() => import('../components/GamePages/GameAdd'))
const GameWordList = lazy(() => import('../components/GamePages/GameWordList'))
const GameWordAdd = lazy(() => import('../components/GamePages/GameWordAdd'))

const ContentCategoryAdd = lazy(() =>
  import('../components/ContentCategoryPages/ContentCategoryAdd')
)
const ContentCategoryList = lazy(() =>
  import('../components/ContentCategoryPages/ContentCategoryList')
)

const ContentGalleryList = lazy(() =>
  import('../components/ContentGalleryPages/ContentGalleryList')
)
const ContentGalleryAdd = lazy(() =>
  import('../components/ContentGalleryPages/ContentGalleryAdd')
)
const ContentGallerySEO = lazy(() =>
  import('../components/ContentGalleryPages/ContentGallerySEO')
)

const VideoCategoryList = lazy(() =>
  import('../components/VideoCategoryPages/VideoCategoryList')
)
const VideoCategorySEO = lazy(() =>
  import('../components/VideoCategoryPages/VideoCategorySEO')
)
const VideoCategoryAdd = lazy(() =>
  import('../components/VideoCategoryPages/VideoCategoryAdd')
)

const VideoGalleryList = lazy(() =>
  import('../components/VideoGalleryPages/VideoGalleryList')
)
const VideoGallerySEO = lazy(() =>
  import('../components/VideoGalleryPages/VideoGallerySEO')
)
const VideoGalleryAdd = lazy(() =>
  import('../components/VideoGalleryPages/VideoGalleryAdd')
)

const MediaList = lazy(() => import('../components/MediaPages/MediaList'))
const MediaAdd = lazy(() => import('../components/MediaPages/MediaAdd'))

const CourseExtensionList = lazy(() =>
  import('../components/CourseExtensionPages/CourseExtensionList')
)

const CourseExtensionAdd = lazy(() =>
  import('../components/CourseExtensionPages/CourseExtensionAdd')
)

const ExaminationList = lazy(() =>
  import('../components/ExaminationPages/ExaminationList')
)
const ExaminationAdd = lazy(() =>
  import('../components/ExaminationPages/ExaminationAdd')
)
const ExaminationQuestionEdit = lazy(() =>
  import('../components/ExaminationPages/ExaminationQuestionEdit')
)

const WorkshopList = lazy(() =>
  import('../components/WorkshopPages/WorkshopList')
)
const WorkshopAgenda = lazy(() =>
  import('../components/WorkshopPages/WorkshopAgenda')
)
const WorkshopAgendaAdd = lazy(() =>
  import('../components/WorkshopPages/WorkshopAgendaAdd')
)
const WorkshopTag = lazy(() =>
  import('../components/WorkshopPages/WorkshopTag')
)
const WorkshopQr = lazy(() =>
  import('../components/WorkshopPages/WorkshopQr')
)

const TagList = lazy(() => import('../components/TagPages/TagList'))
const TagAdd = lazy(() => import('../components/TagPages/TagAdd'))
const TagSEO = lazy(() => import('../components/TagPages/TagSEO'))

const Learn2TradeList = lazy(() =>
  import('../components/Learn2TradePages/Learn2TradeList')
)
const Learn2TradeSEO = lazy(() =>
  import('../components/Learn2TradePages/Learn2TradeSEO')
)
const Learn2TradeAdd = lazy(() =>
  import('../components/Learn2TradePages/Learn2TradeAdd')
)

const Learn2InvestList = lazy(() =>
  import('../components/Learn2InvestPages/Learn2InvestList')
)
const Learn2InvestAdd = lazy(() =>
  import('../components/Learn2InvestPages/Learn2InvestAdd')
)
const Learn2InvestSEO = lazy(() =>
  import('../components/Learn2InvestPages/Learn2InvestSEO')
)

const OrientationList = lazy(() =>
  import('../components/OrientationPages/OrientationList')
)
const OrientationAdd = lazy(() =>
  import('../components/OrientationPages/OrientationAdd')
)

const RoleList = lazy(() => import('../components/RolePages/RoleList'))
const RoleAdd = lazy(() => import('../components/RolePages/RoleAdd'))
const RoleView = lazy(() => import('../components/RolePages/RoleView'))

const FileTypeList = lazy(() =>
  import('../components/FileTypePages/FileTypeList')
)
const FileTypeAdd = lazy(() =>
  import('../components/FileTypePages/FileTypeAdd')
)

const Face2FaceCategoryList = lazy(() =>
  import('../components/Face2FaceCategoryPages/Face2FaceCategoryList')
)
const Face2FaceCategoryAdd = lazy(() =>
  import('../components/Face2FaceCategoryPages/Face2FaceCategoryAdd')
)
const Face2FaceCategorySEO = lazy(() =>
  import('../components/Face2FaceCategoryPages/Face2FaceCategorySEO')
)

const Face2FaceVideoList = lazy(() =>
  import('../components/Face2FaceVideoPages/Face2FaceVideoList')
)

const SubscriptionPlanList = lazy(() =>
  import('../components/SubscriptionPlanPages/SubscriptionPlanList')
)
const SubscriptionPlanSEO = lazy(() =>
  import('../components/SubscriptionPlanPages/SubscriptionPlanSEO')
)
const SubscriptionPlanUserList = lazy(() =>
  import('../components/SubscriptionPlanPages/SubscriptionPlanUserList')
)
const SubscriptionPlanUserDetail = lazy(() =>
  import('../components/SubscriptionPlanPages/SubscriptionPlanUserDetail')
)
const SubscriptionPlanAdd = lazy(() =>
  import('../components/SubscriptionPlanPages/SubscriptionPlanAdd')
)
const SubscriptionPlanTier = lazy(() =>
  import('../components/SubscriptionPlanPages/SubscriptionPlanTier')
)
const VernacularLanguageList = lazy(() =>
  import('../components/VernacularLanguagePages/VernacularLanguageList')
)
const VernacularLanguageAdd = lazy(() =>
  import('../components/VernacularLanguagePages/VernacularLanguageAdd')
)

const HomeRibonList = lazy(() =>
  import('../components/HomeRibonPages/HomeRibonList')
)
const HomeRibonAdd = lazy(() =>
  import('../components/HomeRibonPages/HomeRibonAdd')
)

const CategoryAdd = lazy(() =>
  import('../components/CategoryPages/CategoryAdd')
)
const CategoryList = lazy(() =>
  import('../components/CategoryPages/CategoryList')
)

const RequestCallList = lazy(() =>
  import('../components/RequestCallPages/RequestCallList')
)

const PushNotificationList = lazy(() =>
  import('../components/PushNotificationPages/PushNotificationList')
)
const PushNotificationAdd = lazy(() =>
  import('../components/PushNotificationPages/PushNotificationAdd')
)

const TestList = lazy(() => import('../components/TestPages/TestList'))
const TestSEO = lazy(() => import('../components/TestPages/TestSEO'))
const TestQuestionList = lazy(() =>
  import('../components/TestPages/TestQuestionList')
)
const TestAdd = lazy(() => import('../components/TestPages/TestAdd'))
const TestUserList = lazy(() => import('../components/TestPages/TestUserList'))
const TestLeaderBoard = lazy(() =>
  import('../components/TestPages/TestLeaderBoard')
)
const TestQuestionAdd = lazy(() =>
  import('../components/TestPages/TestQuestionAdd')
)

const DiscussionForumList = lazy(() =>
  import('../components/DiscussionForumPages/DiscussionForumList')
)
const DiscussionForumReply = lazy(() =>
  import('../components/DiscussionForumPages/DiscussionReply')
)

const UserFeedbackTypes = lazy(() =>
  import('../components/UserFeedbackPages/UserFeedbackTypes')
)

const UserFeedbackList = lazy(() =>
  import('../components/UserFeedbackPages/UserFeedbackList')
)

const CourseUpdateText = lazy(() =>
  import('../components/CoursesPage/CourseUpdateText')
)
const CourseTag = lazy(() =>
  import('../components/CoursesPage/CourseTag')
)
const CourseCategoryEdit = lazy(() =>
  import('../components/CoursesPage/CourseCategoryEdit')
)
const CourseIosTier = lazy(() =>
  import('../components/CoursesPage/CourseIosTier')
)
const CourseReplaceAuthor = lazy(() =>
  import('../components/CoursesPage/CourseReplaceAuthor')
)
const CourseTranscribeList = lazy(() =>
  import('../components/CoursesPage/CourseTranscribeList')
)
const CourseTranscribeEdit = lazy(() =>
  import('../components/CoursesPage/CourseTranscribeEdit')
)
const SalesReport = lazy(() => import('../components/CoursesPage/SalesReport'))
const CourseSEO = lazy(() => import('../components/CoursesPage/CourseSEO'))
const CourseBatch = lazy(() => import('../components/CoursesPage/CourseBatch'))

const IosAppTierList = lazy(() =>
  import('../components/IosAppTierPages/IosAppTierList')
)
const IosAppTierAdd = lazy(() =>
  import('../components/IosAppTierPages/IosAppTierAdd')
)

const AdmissionType = lazy(() => import('../components/AdmissionDataPages/AdmissionType'))
const AdmissionCourse = lazy(() => import('../components/AdmissionDataPages/AdmissionCourse'))
const AdmissionCombo = lazy(() => import('../components/AdmissionDataPages/AdmissionCombo'))

const OfferList = lazy(() => import('../components/OfferPages/OfferList'))
const OfferSEO = lazy(() => import('../components/OfferPages/OfferSEO'))
const OfferAdd = lazy(() => import('../components/OfferPages/OfferAdd'))
const CourseCommentList = lazy(() =>
  import('../components/CourseCommentPages/CourseCommentList')
)
const CourseCommentView = lazy(() =>
  import('../components/CourseCommentPages/CourseCommentView')
)

const Face2FaceVideoSEO = lazy(() => import('../components/Face2FaceVideoPages/Face2FaceVideoSEO'))
const Face2FaceVideoAdd = lazy(() => import('../components/Face2FaceVideoPages/Face2FaceVideoAdd'))

const FeedbackSettingList = lazy(() => import('../components/FeedbackSettingsPages/FeedbackSettingList'))
const FeedbackSettingAdd = lazy(() => import('../components/FeedbackSettingsPages/FeedbackSettingAdd'))

const AffiliateList = lazy(() => import('../components/AffiliatePages/AffiliateList'))
const AffiliateRegistrationList = lazy(() => import('../components/AffiliatePages/AffiliateRegistrationList'))
const AffiliateRegistrationDetails = lazy(() => import('../components/AffiliatePages/AffiliateRegistrationDetails'))
const AffiliateUser = lazy(() => import('../components/AffiliatePages/AffiliateUser'))
const AffiliateView = lazy(() => import('../components/AffiliatePages/AffiliateView'))
const AffiliateBankDetails = lazy(() => import('../components/AffiliatePages/AffiliateBankDetails'))
const AffiliateSaleList = lazy(() => import('../components/AffiliatePages/AffiliateSaleList'))

const AffiliateCategoryList = lazy(() => import('../components/AffiliateCategoryPages/AffilateCategoryList'))
const AffiliateCategoryAdd = lazy(() => import('../components/AffiliateCategoryPages/AffiliateCategoryAdd'))
const AffiliateCategoryItems = lazy(() => import('../components/AffiliateCategoryPages/AffiliateCategoryItems'))

const AffiliateEdit = lazy(() => import('../components/AffiliatePages/AffiliateEdit'))

const WorkshopUsers = lazy(() => import('../components/WorkshopPages/WorkshopUsers'))
const WorkshopUserAdd = lazy(() => import('../components/WorkshopPages/WorkshopUserAdd'))
const WorkshopAdd = lazy(() => import('../components/WorkshopPages/WorkshopAdd'))
const WorkshopSEO = lazy(() => import('../components/WorkshopPages/WorkshopSEO'))

const UserDeleteRequestList = lazy(() => import('../components/UserDeleteRequestPages/UserDeleteRequestList'))


const ArticleCategoryList = lazy(() => import('../components/ArticleCategoryPages/ArticleCategoryList'))
const ArticleCategorySEO = lazy(() => import('../components/ArticleCategoryPages/ArticleCategorySEO'))
const ArticleCategoryAdd = lazy(() => import('../components/ArticleCategoryPages/ArticleCategoryAdd'))

const ManualCartList = lazy(() => import('../components/ManualCartPages/ManualCartList'))
const ManualCartEdit = lazy(() => import('../components/ManualCartPages/ManualCartEdit'))

const StudentAmbassadorTypes = lazy(() => import('../components/StudentAmbassadorPages/StudentAmbassadorTypes'))
const StudentAmbassadorReport = lazy(() => import('../components/StudentAmbassadorPages/StudentAmbassadorReport'))
const StudentAmbassadorList = lazy(() => import('../components/StudentAmbassadorPages/StudentAmbassadorList'))
const StudentAmbassadorView = lazy(() => import('../components/StudentAmbassadorPages/StudentAmbassadorView'))
const StudentRegistrationList = lazy(() => import('../components/StudentAmbassadorPages/StudentRegistrationList'))
const StudentRegistrationDetails = lazy(() => import('../components/StudentAmbassadorPages/StudentRegistrationDetails'))
const StudentUser = lazy(() => import('../components/StudentAmbassadorPages/StudentUser'))

const CheckUserPlanList = lazy(() => import('../components/CheckUserPlanPages/CheckUserPlanList'))

const CheckPaymentList = lazy(() => import('../components/PaymentCheckPages/CheckPaymentList'))

const PaymentList = lazy(() => import('../components/PendingPaymentPages/PaymentList'))

const PaymentCollectionList = lazy(() => import('../components/PaymentLinkGenerationPages/PaymentCollectionList'))

const PaymentCollectionAdd = lazy(() => import('../components/PaymentLinkGenerationPages/PaymentCollectionAdd'))


const BulkEmailList = lazy(() => import('../components/BulkEmailPages/BulkEmailList'))
const BulkEmailAdd = lazy(() => import('../components/BulkEmailPages/BulkEmailAdd'))
const BulkEmailProcessList = lazy(() => import('../components/BulkEmailPages/BulkEmailProcessList'))

const AffiliateSaleType = lazy(() => import('../components/AffiliateSalePages/AffiliateSaleType'))
const AffiliateSaleReportList = lazy(() => import('../components/AffiliateSalePages/AffiliateSaleReportList'))
const AffiliateSaleMisReport = lazy(() => import('../components/AffiliateSalePages/AffiliateSaleMisReport'))

const AffiliateSaleCommission = lazy(() => import('../components/AffiliateSalePages/AffiliateSaleCommission'))
const AffiliateSaleUserList = lazy(() => import('../components/AffiliateSalePages/AffiliateSaleUserList'))
const AffiliateGenuineReport = lazy(() => import('../components/AffiliateSalePages/AffiliateGenuineReport'))

const FaqHelpList = lazy(() => import('../components/FaqHelpPages/FaqHelpList'))
const FaqHelpAdd = lazy(() => import('../components/FaqHelpPages/FaqHelpAdd'))

const NseCertificateType = lazy(() => import('../components/NseCertificatePages/NseCertificateType'))
const NseCertificateRequestList = lazy(() => import('../components/NseCertificatePages/NseCertificateRequestList'))
const NseCertifiedCourseList = lazy(() => import('../components/NseCertificatePages/NseCertifiedCourseList'))
const NseCertifiedCourseAdd = lazy(() => import('../components/NseCertificatePages/NseCertifiedCourseAdd'))

const NseApprovalList = lazy(() => import('../components/NseApprovalPages/NseApprovalList'))

const BlockDomainList = lazy(() => import('../components/BlockDomainPages/BlockDomainList'))
const BlockDomainAdd = lazy(() => import('../components/BlockDomainPages/BlockDomainAdd'))

const JobList = lazy(() => import('../components/JobPages/JobList'))
const JobAdd = lazy(() => import('../components/JobPages/JobAdd'))
const JobSEO = lazy(() => import('../components/JobPages/JobSEO'))
const JobTag = lazy(() => import('../components/JobPages/JobTag'))
const JobApplication = lazy(() => import('../components/JobPages/JobApplication'))

const CourseRegionList = lazy(() => import('../components/CourseRegionPages/CourseRegionList'))
const CourseRegionAdd = lazy(() => import('../components/CourseRegionPages/CourseRegionAdd'))
const CourseRegionSEO = lazy(() => import('../components/CourseRegionPages/CourseRegionSEO'))

const SliderList = lazy(() => import('../components/SliderPages/SliderList'))
const SliderAdd = lazy(() => import('../components/SliderPages/SliderAdd'))

const CouponReportTypes = lazy(() => import('../components/CouponReportPages/CouponReportTypes'))
const CouponReportList = lazy(() => import('../components/CouponReportPages/CouponReportList'))
const CouponReportUsage = lazy(() => import('../components/CouponReportPages/CouponReportUsage'))

const InvoiceList = lazy(() => import('../components/InvoicePages/InvoiceList'))
const InvoiceView = lazy(() => import('../components/InvoicePages/InvoiceView'))

const AuthorSaleList = lazy(() => import('../components/AuthorSalePages/AuthorSaleList'))

const MisReportList = lazy(() => import('../components/MisReportPages/MisReportList'))

const MisLearningList = lazy(() => import('../components/MisLearningPages/MisLearningList'))
const MisLearningView = lazy(() => import('../components/MisLearningPages/MisLearningView'))

const AccessApprovalList = lazy(() => import('../components/AccessApprovalPages/AccessApprovalList'))
const AccessApprovalEdit = lazy(() => import('../components/AccessApprovalPages/AccessApprovalEdit'))

const NcdexApprovalList = lazy(() => import('../components/NcdexApprovalPages/NcdexApprovalList'))

const NcdexCertificateType = lazy(() => import('../components/NcdexCertificatePages/NcdexCertificateType'))
const NcdexCertificateRequestList = lazy(() => import('../components/NcdexCertificatePages/NcdexCertificateRequestList'))
const NcdexCertifiedCourseList = lazy(() => import('../components/NcdexCertificatePages/NcdexCertifiedCourseList'))
const NcdexCertifiedCourseAdd = lazy(() => import('../components/NcdexCertificatePages/NcdexCertifiedCourseAdd'))

const BankInterestRateList = lazy(() => import('../components/BankInterestRatePages/BankInterestRateList'))
const BankInterestRateAdd = lazy(() => import('../components/BankInterestRatePages/BankInterestRateAdd'))

const AuthorRequestList = lazy(() => import('../components/AuthorRequestPages/AuthorRequestList'))
const AuthorRequestEdit = lazy(() => import('../components/AuthorRequestPages/AuthorRequestEdit'))

const Face2FaceAuthorList = lazy(() => import('../components/Face2FaceAuthorPages/Face2FaceAuthorList'))
const Face2FaceAuthorAdd = lazy(() => import('../components/Face2FaceAuthorPages/Face2FaceAuthorAdd'))

const StockedgeClubUserDetailList = lazy(() => import('../components/StockedgeClubUserDetailPages/StockedgeClubUserDetailList'))
const StockedgeClubUserDetail = lazy(() => import('../components/StockedgeClubUserDetailPages/StockedgeClubUserDetail'))

const PartnerCouponLogList = lazy(() => import('../components/PartnerCouponPages/PartnerCouponLogList'))
const PartnerCouponImport = lazy(() => import('../components/PartnerCouponPages/PartnerCouponImport'))

const ProductDeleteLogPage = lazy(() => import('../components/ProductDeleteLogPages/ProductDeleteLogPage'))
const ProductDeleteLogList = lazy(() => import('../components/ProductDeleteLogPages/ProductDeleteLogList'))
const ProductDeleteLogEdit = lazy(() => import('../components/ProductDeleteLogPages/ProductDeleteLogEdit'))

const ManualAccessRequestList = lazy(() => import('../components/ManualAccessRequestPages/ManualAccessRequestList'))
const ManualAccessRequestAdd = lazy(() => import('../components/ManualAccessRequestPages/ManualAccessRequestAdd'))

const ManualCourseAccessList = lazy(() => import('../components/ManualCourseAccessPages/ManualCourseAccessList'))
const ManualCourseAccessAdd = lazy(() => import('../components/ManualCourseAccessPages/ManualCourseAccessAdd'))

const ManualPlanAccessList = lazy(() => import('../components/ManualPlanAccessPages/ManualPlanAccessList'))
const ManualPlanAccessAdd = lazy(() => import('../components/ManualPlanAccessPages/ManualPlanAccessAdd'))

const AuthorList = lazy(() => import('../components/AuthorPages/AuthorList'))
const AuthorExistingUserAdd = lazy(() => import('../components/AuthorPages/AuthorExistingUserAdd'))
const AuthorEdit = lazy(() => import('../components/AuthorPages/AuthorEdit'))
const AuthorSEO = lazy(() => import('../components/AuthorPages/AuthorSEO'))
const AuthorCommission = lazy(() => import('../components/AuthorPages/AuthorCommission'))

const AuthorCommissionList = lazy(() => import('../components/AuthorCommissionPages/AuthorCommissionList'))
const AuthorCommissionAdd = lazy(() => import('../components/AuthorCommissionPages/AuthorCommissionAdd'))
const AuthorCommissionItems = lazy(() => import('../components/AuthorCommissionPages/AuthorCommissionItems'))

const ArticleList = lazy(() => import('../components/ArticlePages/ArticleList'))
const ArticleAdd = lazy(() => import('../components/ArticlePages/ArticleAdd'))
const ArticleSEO = lazy(() => import('../components/ArticlePages/ArticleSEO'))
const ArticleUnitList = lazy(() => import('../components/ArticlePages/ArticleUnitList'))
const ArticleUnitAdd = lazy(() => import('../components/ArticlePages/ArticleUnitAdd'))
const ArticleUnitSEO = lazy(() => import('../components/ArticlePages/ArticleUnitSEO'))
const ArticleAnalytic = lazy(() => import('../components/ArticlePages/ArticleAnalytic'))

const AuthorReportList = lazy(() => import('../components/AuthorReportPages/AuthorReportList'))

const ModuleCategoryList = lazy(() => import('../components/ModuleCategoryPages/ModuleCategoryList'))
const ModuleCategoryAdd = lazy(() => import('../components/ModuleCategoryPages/ModuleCategoryAdd'))

const WebinarAnalyticsList = lazy(() => import('../components/WebinarAnalyticsPags/WebinarAnalyticsList'))

const SiteMap = lazy(() => import('../components/SiteMapPages/SiteMap'))

const CourseCaptionList = lazy(() => import('../components/CourseCaptionPages/CourseCaptionList'))
const CourseCaptionAdd = lazy(() => import('../components/CourseCaptionPages/CourseCaptionAdd'))

const CourseCompletionBulkEmailList = lazy(() => import('../components/CourseCompletionBulkEmailPages/CourseCompletionBulkEmailList'))

const UnsubscribeList = lazy(() => import('../components/UnsubscribePages/UnsubscribeList'))

const AllInOneCertificateList = lazy(() => import('../components/AllInOneCertificatePages/AllInOneCertificateList'))

const WebinarCaptionList = lazy(() => import('../components/WebinarCaptionPages/WebinarCaptionList'))
const WebinarCaptionAdd = lazy(() => import('../components/WebinarCaptionPages/WebinarCaptionAdd'))

const PopupOfferList = lazy(() => import('../components/PopupOfferPages/PopupOfferList'))
const PopupOfferListAdd = lazy(() => import('../components/PopupOfferPages/PopupOfferListAdd'))


const UserPlanList = lazy(() => import('../components/UserPlanPages/UserPlanList'))
const UserMisList = lazy(() => import('../components/UserMisPages/UserMisList'))

const RouteMain = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/' element={<Layout />}>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/home/list' element={<Dashboard />} />
        <Route path='/course/list/:id' element={<CourseList />} />
        <Route
          path='/course/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseTypes />
            </Suspense>
          }
        />

        <Route
          path='/webinar/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarList />
            </Suspense>
          }
        />
        <Route
          path='/webinar/add'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarAdd />
            </Suspense>
          }
        />
        <Route
          path='/webinar/edit/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarAdd />
            </Suspense>
          }
        />
        <Route
          path='/webinar/seo/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarSEO />
            </Suspense>
          }
        />
        <Route
          path='/webinar/users/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarUser />
            </Suspense>
          }
        />
        <Route
          path='/webinar/users/:add/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarUser />
            </Suspense>
          }
        />
        <Route
          path='/webinar/video/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarVideo />
            </Suspense>
          }
        />
        <Route
          path='/webinar/links/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarUpdateLink />
            </Suspense>
          }
        />
        <Route
          path='/webinar/zoom-attendees/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarZoomAttendee />
            </Suspense>
          }
        />
        <Route
          path='/webinar/ios/add/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarIosTier />
            </Suspense>
          }
        />
        <Route
          path='/webinar/tag/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarTag />
            </Suspense>
          }
        />
        <Route
          path='/webinar/update-category/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WebinarCategoryEdit />
            </Suspense>
          }
        />
        <Route
          path='/nps/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <NPSScore />
            </Suspense>
          }
        />
        <Route
          path='/nps/feedback/:type'
          element={
            <Suspense fallback={<>Loading</>}>
              <NPSFeedback />
            </Suspense>
          }
        />
        <Route
          path='/user/list'
          element={
            <Suspense fallback={<>...</>}>
              <UsersList />
            </Suspense>
          }
        />
        <Route
          path='/user/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <UserView />
            </Suspense>
          }
        />
        <Route
          path='/user/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <UserEdit />
            </Suspense>
          }
        />
        <Route
          path='/user/items/:id'
          element={
            <Suspense fallback={<>...</>}>
              <UserItem />
            </Suspense>
          }
        />
        <Route
          path='/user/details/:id/:item_type/:user_id'
          element={
            <Suspense fallback={<>...</>}>
              <UserLearningList />
            </Suspense>
          }
        />
        <Route
          path='/course-delivery-mode/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseDeliveryModeList />
            </Suspense>
          }
        />
        <Route
          path='/course-delivery-mode/add'
          element={
            <Suspense fallback={<>...</>}>
              <CourseDeliveryModeAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-delivery-mode/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseDeliveryModeAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-delivery-mode/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseDeliveryModeSEO />
            </Suspense>
          }
        />
        <Route
          path='/course-subject/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseSubjectList />
            </Suspense>
          }
        />
        <Route
          path='/course-subject/add'
          element={
            <Suspense fallback={<>...</>}>
              <CourseSubjectAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-subject/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseSubjectAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-subject/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseSubjectSEO />
            </Suspense>
          }
        />
        <Route
          path='/course-level/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLevelList />
            </Suspense>
          }
        />
        <Route
          path='/course-level/add'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLevelAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-level/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLevelAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-level/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLevelSEO />
            </Suspense>
          }
        />
        <Route
          path='/course-language/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLanguageList />
            </Suspense>
          }
        />
        <Route
          path='/course-language/add'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLanguageAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-language/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLanguageAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-language/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseLanguageSEO />
            </Suspense>
          }
        />
        <Route
          path='/tax/list'
          element={
            <Suspense fallback={<>...</>}>
              <TaxList />
            </Suspense>
          }
        />
        <Route
          path='/tax/add'
          element={
            <Suspense fallback={<>...</>}>
              <TaxAdd />
            </Suspense>
          }
        />

        <Route
          path='/tax/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TaxAdd />
            </Suspense>
          }
        />

        <Route
          path='/country/list'
          element={
            <Suspense fallback={<>...</>}>
              <CountryList />
            </Suspense>
          }
        />
        <Route
          path='/country/add'
          element={
            <Suspense fallback={<>...</>}>
              <CountryAdd />
            </Suspense>
          }
        />
        <Route
          path='/country/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CountryAdd />
            </Suspense>
          }
        />

        <Route
          path='/state/list'
          element={
            <Suspense fallback={<>...</>}>
              <StateList />
            </Suspense>
          }
        />
        <Route
          path='/state/add'
          element={
            <Suspense fallback={<>...</>}>
              <StateAdd />
            </Suspense>
          }
        />
        <Route
          path='/state/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <StateAdd />
            </Suspense>
          }
        />

        <Route
          path='/city/list'
          element={
            <Suspense fallback={<>...</>}>
              <CityList />
            </Suspense>
          }
        />
        <Route
          path='/city/add'
          element={
            <Suspense fallback={<>...</>}>
              <CityAdd />
            </Suspense>
          }
        />
        <Route
          path='/city/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CityAdd />
            </Suspense>
          }
        />
        <Route
          path='/module/list'
          element={
            <Suspense fallback={<>...</>}>
              <ModuleList />
            </Suspense>
          }
        />
        <Route
          path='/module/add'
          element={
            <Suspense fallback={<>...</>}>
              <ModuleAdd />
            </Suspense>
          }
        />
        <Route
          path='/module/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ModuleAdd />
            </Suspense>
          }
        />
        <Route
          path='/module-permission/list'
          element={
            <Suspense fallback={<>...</>}>
              <ModulePermissionList />
            </Suspense>
          }
        />
        <Route
          path='/module-permission/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ModulePermissionEdit />
            </Suspense>
          }
        />
        <Route
          path='/module-permission/add'
          element={
            <Suspense fallback={<>...</>}>
              <ModulePermissionAdd />
            </Suspense>
          }
        />
        <Route
          path='/combo/list'
          element={
            <Suspense fallback={<>...</>}>
              <ComboList />
            </Suspense>
          }
        />
        <Route
          path='/combo/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ComboAdd />
            </Suspense>
          }
        />
        <Route
          path='/combo/update-tier/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ComboIosTier />
            </Suspense>
          }
        />
        <Route
          path='/combo/add'
          element={
            <Suspense fallback={<>...</>}>
              <ComboAdd />
            </Suspense>
          }
        />
        <Route
          path='/combo/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ComboSEO />
            </Suspense>
          }
        />
        <Route
          path='/combo/update-category/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <ComboCategoryEdit />
            </Suspense>
          }
        />
        <Route
          path='/combo/batch/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <ComboBatch />
            </Suspense>
          }
        />
        <Route
          path='/combo/batch/add/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <ComboBatchAdd />
            </Suspense>
          }
        />
        <Route
          path='/combo/batch/edit/:id/:batch_id'
          element={
            <Suspense fallback={<>Loading</>}>
              <ComboBatchAdd />
            </Suspense>
          }
        />
        <Route
          path='/combo/item/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <ComboItem />
            </Suspense>
          }
        />
        <Route
          path='/static-page/list'
          element={
            <Suspense fallback={<>...</>}>
              <ContentList />
            </Suspense>
          }
        />
        <Route
          path='/static-page/add'
          element={
            <Suspense fallback={<>...</>}>
              <ContentAdd />
            </Suspense>
          }
        />
        <Route
          path='/static-page/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ContentAdd />
            </Suspense>
          }
        />
        <Route
          path='/static-page/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ContentSEO />
            </Suspense>
          }
        />
        <Route
          path='/email/list'
          element={
            <Suspense fallback={<>...</>}>
              <EmailList />
            </Suspense>
          }
        />
        <Route
          path='/email/add'
          element={
            <Suspense fallback={<>...</>}>
              <EmailAdd />
            </Suspense>
          }
        />
        <Route
          path='/email/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <EmailAdd />
            </Suspense>
          }
        />
        <Route
          path='/word-glossary/list'
          element={
            <Suspense fallback={<>...</>}>
              <GameList />
            </Suspense>
          }
        />
        <Route
          path='/word-glossary/add'
          element={
            <Suspense fallback={<>...</>}>
              <GameAdd />
            </Suspense>
          }
        />
        <Route
          path='/word-glossary/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <GameAdd />
            </Suspense>
          }
        />
        <Route
          path='/word-glossary/show/:id'
          element={
            <Suspense fallback={<>...</>}>
              <GameWordList />
            </Suspense>
          }
        />
        <Route
          path='/word-glossary/:type/:id'
          element={
            <Suspense fallback={<>...</>}>
              <GameWordAdd />
            </Suspense>
          }
        />
        <Route
          path='/content-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <ContentCategoryList />
            </Suspense>
          }
        />
        <Route
          path='/content-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <ContentCategoryAdd />
            </Suspense>
          }
        />
        <Route
          path='/content-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ContentCategoryAdd />
            </Suspense>
          }
        />

        <Route
          path='/video-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <VideoCategoryList />
            </Suspense>
          }
        />
        <Route
          path='/video-category/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <VideoCategorySEO />
            </Suspense>
          }
        />
        <Route
          path='/video-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <VideoCategoryAdd />
            </Suspense>
          }
        />
        <Route
          path='/video-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <VideoCategoryAdd />
            </Suspense>
          }
        />
        <Route
          path='/content-gallery/list'
          element={
            <Suspense fallback={<>...</>}>
              <ContentGalleryList />
            </Suspense>
          }
        />
        <Route
          path='/content-gallery/add'
          element={
            <Suspense fallback={<>...</>}>
              <ContentGalleryAdd />
            </Suspense>
          }
        />
        <Route
          path='/content-gallery/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ContentGalleryAdd />
            </Suspense>
          }
        />
        <Route
          path='/content-gallery/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ContentGallerySEO />
            </Suspense>
          }
        />
        <Route
          path='/video-gallery/list'
          element={
            <Suspense fallback={<>...</>}>
              <VideoGalleryList />
            </Suspense>
          }
        />
        <Route
          path='/video-gallery/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <VideoGallerySEO />
            </Suspense>
          }
        />
        <Route
          path='/video-gallery/add'
          element={
            <Suspense fallback={<>...</>}>
              <VideoGalleryAdd />
            </Suspense>
          }
        />
        <Route
          path='/video-gallery/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <VideoGalleryAdd />
            </Suspense>
          }
        />
        <Route
          path='/media/list'
          element={
            <Suspense fallback={<>...</>}>
              <MediaList />
            </Suspense>
          }
        />
        <Route
          path='/media/add'
          element={
            <Suspense fallback={<>...</>}>
              <MediaAdd />
            </Suspense>
          }
        />
        <Route
          path='/media/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <MediaAdd />
            </Suspense>
          }
        />
        <Route
          path='/request-extension/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseExtensionList />
            </Suspense>
          }
        />
        <Route
          path='/request-extension/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseExtensionAdd />
            </Suspense>
          }
        />
        <Route
          path='/examination/list'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationList />
            </Suspense>
          }
        />
        <Route
          path='/examination/add'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationAdd />
            </Suspense>
          }
        />
        <Route
          path='/examination/:show/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationAdd />
            </Suspense>
          }
        />
        <Route
          path='/examination/:view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationAdd />
            </Suspense>
          }
        />
        <Route
          path='/examination/:edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationAdd />
            </Suspense>
          }
        />
        <Route
          path='/examination-question/:edit/:questionid/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationQuestionEdit />
            </Suspense>
          }
        />
        <Route
          path='/examination-question/:create/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ExaminationQuestionEdit />
            </Suspense>
          }
        />
        <Route
          path='/workshop/list'
          element={
            <Suspense fallback={<>...</>}>
              <WorkshopList />
            </Suspense>
          }
        />
        <Route
          path='/workshop/agenda/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WorkshopAgenda />
            </Suspense>
          }
        />
        <Route
          path='/workshop/agenda/add/:workshop_id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WorkshopAgendaAdd />
            </Suspense>
          }
        />
        <Route
          path='/workshop/agenda/edit/:workshop_id/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WorkshopAgendaAdd />
            </Suspense>
          }
        />
        <Route
          path='/workshop/tag/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WorkshopTag />
            </Suspense>
          }
        />
        <Route
          path='/workshop/qr/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <WorkshopQr />
            </Suspense>
          }
        />
        <Route
          path='/tag/list'
          element={
            <Suspense fallback={<>...</>}>
              <TagList />
            </Suspense>
          }
        />
        <Route
          path='/tag/add'
          element={
            <Suspense fallback={<>...</>}>
              <TagAdd />
            </Suspense>
          }
        />
        <Route
          path='/tag/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TagAdd />
            </Suspense>
          }
        />
        <Route
          path='/tag/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TagSEO />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-trade/list'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2TradeList />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-trade/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2TradeSEO />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-trade/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2TradeAdd />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-trade/add'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2TradeAdd />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-invest/list'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2InvestList />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-invest/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2InvestSEO />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-invest/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2InvestAdd />
            </Suspense>
          }
        />
        <Route
          path='/learn-to-invest/add'
          element={
            <Suspense fallback={<>...</>}>
              <Learn2InvestAdd />
            </Suspense>
          }
        />
        <Route
          path='/orientation/list'
          element={
            <Suspense fallback={<>...</>}>
              <OrientationList />
            </Suspense>
          }
        />
        <Route
          path='/orientation/add'
          element={
            <Suspense fallback={<>...</>}>
              <OrientationAdd />
            </Suspense>
          }
        />
        <Route
          path='/orientation/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <OrientationAdd />
            </Suspense>
          }
        />
        <Route
          path='/role/list'
          element={
            <Suspense fallback={<>...</>}>
              <RoleList />
            </Suspense>
          }
        />
        <Route
          path='/role/add'
          element={
            <Suspense fallback={<>...</>}>
              <RoleAdd />
            </Suspense>
          }
        />
        <Route
          path='/role/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <RoleAdd />
            </Suspense>
          }
        />
        <Route
          path='/role/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <RoleView />
            </Suspense>
          }
        />
        <Route
          path='/file-type/list'
          element={
            <Suspense fallback={<>...</>}>
              <FileTypeList />
            </Suspense>
          }
        />
        <Route
          path='/file-type/add'
          element={
            <Suspense fallback={<>...</>}>
              <FileTypeAdd />
            </Suspense>
          }
        />
        <Route
          path='/file-type/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <FileTypeAdd />
            </Suspense>
          }
        />
        <Route
          path='/facetoface-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceCategoryList />
            </Suspense>
          }
        />
        <Route
          path='/facetoface-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceCategoryAdd />
            </Suspense>
          }
        />
        <Route
          path='/facetoface-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/facetoface-category/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceCategorySEO />
            </Suspense>
          }
        />
        <Route
          path='/facetoface-video/list'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceVideoList />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/list'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanList />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanSEO />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/users/:id'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanUserList />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/user-detail/:id'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanUserDetail />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/add'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanAdd />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanAdd />
            </Suspense>
          }
        />
        <Route
          path='/subscription-plan/ios/:id'
          element={
            <Suspense fallback={<>...</>}>
              <SubscriptionPlanTier />
            </Suspense>
          }
        />
        <Route
          path='/vernacular-language/list'
          element={
            <Suspense fallback={<>...</>}>
              <VernacularLanguageList />
            </Suspense>
          }
        />
        <Route
          path='/vernacular-language/add'
          element={
            <Suspense fallback={<>...</>}>
              <VernacularLanguageAdd />
            </Suspense>
          }
        />
        <Route
          path='/vernacular-language/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <VernacularLanguageAdd />
            </Suspense>
          }
        />
        <Route
          path='/home-page/list'
          element={
            <Suspense fallback={<>...</>}>
              <HomeRibonList />
            </Suspense>
          }
        />
        <Route
          path='/home-page/add'
          element={
            <Suspense fallback={<>...</>}>
              <HomeRibonAdd />
            </Suspense>
          }
        />
        <Route
          path='/home-page/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <HomeRibonAdd />
            </Suspense>
          }
        />

        <Route
          path='/category/list'
          element={
            <Suspense fallback={<>...</>}>
              <CategoryList />
            </Suspense>
          }
        />
        <Route
          path='/category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CategoryAdd />
            </Suspense>
          }
        />
        <Route
          path='/category/add'
          element={
            <Suspense fallback={<>...</>}>
              <CategoryAdd />
            </Suspense>
          }
        />
        <Route
          path='/requestcall/list'
          element={
            <Suspense fallback={<>...</>}>
              <RequestCallList />
            </Suspense>
          }
        />
        <Route
          path='/push-notification/list'
          element={
            <Suspense fallback={<>...</>}>
              <PushNotificationList />
            </Suspense>
          }
        />
        <Route
          path='/push-notification/add'
          element={
            <Suspense fallback={<>...</>}>
              <PushNotificationAdd />
            </Suspense>
          }
        />
        <Route
          path='/push-notification/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <PushNotificationAdd />
            </Suspense>
          }
        />
        <Route
          path='/glossary/list'
          element={
            <Suspense fallback={<>...</>}>
              <TestList />
            </Suspense>
          }
        />
        <Route
          path='/glossary/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TestSEO />
            </Suspense>
          }
        />
        <Route
          path='/glossary/view-question/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TestQuestionList />
            </Suspense>
          }
        />
        <Route
          path='/glossary/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TestAdd />
            </Suspense>
          }
        />
        <Route
          path='/glossary/add'
          element={
            <Suspense fallback={<>...</>}>
              <TestAdd />
            </Suspense>
          }
        />
        <Route
          path='/glossary/user-list/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TestUserList />
            </Suspense>
          }
        />
        <Route
          path='/glossary/leader-board/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TestLeaderBoard />
            </Suspense>
          }
        />
        <Route
          path='/glossary/add-question/:glossary_id'
          element={
            <Suspense fallback={<>...</>}>
              <TestQuestionAdd />
            </Suspense>
          }
        />
        <Route
          path='/glossary/add-question/:glossary_id/:id'
          element={
            <Suspense fallback={<>...</>}>
              <TestQuestionAdd />
            </Suspense>
          }
        />

        <Route
          path='/discussion-forum/list'
          element={
            <Suspense fallback={<>...</>}>
              <DiscussionForumList />
            </Suspense>
          }
        />
        <Route
          path='/discussion-forum/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <DiscussionForumReply />
            </Suspense>
          }
        />
        <Route
          path='/user-feedback/list'
          element={
            <Suspense fallback={<>...</>}>
              <UserFeedbackTypes />
            </Suspense>
          }
        />
        <Route
          path='/user-feedback/list/:type'
          element={
            <Suspense fallback={<>...</>}>
              <UserFeedbackList />
            </Suspense>
          }
        />
        <Route
          path='/course-comment/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseCommentList />
            </Suspense>
          }
        />
        <Route
          path='/course-comment/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseCommentView />
            </Suspense>
          }
        />
        <Route
          path='/coupon/list'
          element={
            <Suspense fallback={<>...</>}>
              <CouponTypes />
            </Suspense>
          }
        />
        <Route
          path='/coupon/list/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CouponList />
            </Suspense>
          }
        />
        <Route
          path='/coupon/view/:id/:coupon_id'
          element={
            <Suspense fallback={<>...</>}>
              <CouponView />
            </Suspense>
          }
        />
        <Route
          path='/coupon/edit/:id/:couponid'
          element={
            <Suspense fallback={<>...</>}>
              <CouponAdd />
            </Suspense>
          }
        />
        <Route
          path='/coupon/:add/add'
          element={
            <Suspense fallback={<>...</>}>
              <CouponAdd />
            </Suspense>
          }
        />
        <Route
          path='/coupon/add'
          element={<Navigate to="/coupon/course-coupon/add" />}
        />
        <Route
          path='/course/update-summary/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseUpdateText />
            </Suspense>
          }
        />
        <Route
          path='/course/tag-update/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseTag />
            </Suspense>
          }
        />
        <Route
          path='/course/update-category/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseCategoryEdit />
            </Suspense>
          }
        />
        <Route
          path='/course/update-tier/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseIosTier />
            </Suspense>
          }
        />
        <Route
          path='/course/replace-author/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseReplaceAuthor />
            </Suspense>
          }
        />
        <Route
          path='/course/transcribe/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseTranscribeList />
            </Suspense>
          }
        />
        <Route
          path='/course/transcribe/edit/:course_id/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseTranscribeEdit />
            </Suspense>
          }
        />
        <Route
          path='/course/sales/:id'
          element={
            <Suspense fallback={<>...</>}>
              <SalesReport />
            </Suspense>
          }
        />
        <Route
          path='/course/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseSEO />
            </Suspense>
          }
        />
        <Route
          path='/course/batch/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseBatch />
            </Suspense>
          }
        />

        <Route
          path='/ios-app-tier/list'
          element={
            <Suspense fallback={<>...</>}>
              <IosAppTierList />
            </Suspense>
          }
        />
        <Route
          path='/ios-app-tier/add'
          element={
            <Suspense fallback={<>...</>}>
              <IosAppTierAdd />
            </Suspense>
          }
        />
        <Route
          path='/ios-app-tier/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <IosAppTierAdd />
            </Suspense>
          }
        />
        <Route
          path='/admission/list'
          element={
            <Suspense fallback={<>...</>}>
              <AdmissionType />
            </Suspense>
          }
        />
        <Route path='/admission/course'
          element={
            <Suspense fallback={<>...</>}>
              <AdmissionCourse />
            </Suspense>
          }
        />
        <Route path='/admission/combo'
          element={
            <Suspense fallback={<>...</>}>
              <AdmissionCombo />
            </Suspense>
          }
        />
        <Route path='/offer/list'
          element={
            <Suspense fallback={<>...</>}>
              <OfferList />
            </Suspense>
          }
        />
        <Route
          path='/offer/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <OfferSEO />
            </Suspense>
          }
        />
        <Route
          path='/offer/add'
          element={
            <Suspense fallback={<>...</>}>
              <OfferAdd />
            </Suspense>
          }
        />
        <Route
          path='/offer/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <OfferAdd />
            </Suspense>
          }
        />
        <Route path='/facetoface-video/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceVideoSEO />
            </Suspense>
          }
        />
        <Route path='/facetoface-video/add'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceVideoAdd />
            </Suspense>
          }
        />
        <Route path='/facetoface-video/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceVideoAdd />
            </Suspense>
          }
        />
        <Route path='/feedback-setting/list'
          element={
            <Suspense fallback={<>...</>}>
              <FeedbackSettingList />
            </Suspense>
          }
        />
        <Route path='/feedback-setting/add'
          element={
            <Suspense fallback={<>...</>}>
              <FeedbackSettingAdd />
            </Suspense>
          }
        />
        <Route path='/feedback-setting/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <FeedbackSettingAdd />
            </Suspense>
          }
        />
        <Route path='/affiliate/list'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateList />
            </Suspense>
          }
        />
        <Route path='/affiliate/user-registration/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateRegistrationList />
            </Suspense>
          }
        />
        <Route path='/affiliate/users/:year/:month/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateRegistrationDetails />
            </Suspense>
          }
        />
        <Route path='/affiliate/user/:year/:month/:affiliate_id/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateUser />
            </Suspense>
          }
        />
        <Route path='/affiliate/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateView />
            </Suspense>
          }
        />
        <Route path='/affiliate/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateEdit />
            </Suspense>
          }
        />
        <Route path='/affiliate/bank-details/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateBankDetails />
            </Suspense>
          }
        />
        <Route path='/affiliate/salelist/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateSaleList />
            </Suspense>
          }
        />

        <Route path='/affiliate-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateCategoryList />
            </Suspense>
          }
        />
        <Route path='/affiliate-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/affiliate-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/affiliate-category/items/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateCategoryItems />
            </Suspense>
          }
        />

        <Route path='/manual-cart/list'
          element={
            <Suspense fallback={<>...</>}>
              <ManualCartList />
            </Suspense>
          }
        />
        <Route path='/manual-cart/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ManualCartEdit />
            </Suspense>
          }
        />
        <Route path='/user-delete-request/list'
          element={
            <Suspense fallback={<>...</>}>
              <UserDeleteRequestList />
            </Suspense>
          }
        />

        <Route path='/bulk-email/list'
          element={
            <Suspense fallback={<>...</>}>
              <BulkEmailList />
            </Suspense>
          }
        />
        <Route path='/bulk-email/add'
          element={
            <Suspense fallback={<>...</>}>
              <BulkEmailAdd />
            </Suspense>
          }
        />
        <Route path='/bulk-email/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <BulkEmailAdd />
            </Suspense>
          }
        />
        <Route path='/bulk-email/send-list/:id'
          element={
            <Suspense fallback={<>...</>}>
              <BulkEmailProcessList />
            </Suspense>
          }
        />
        <Route path='/workshop/users/:id'
          element={
            <Suspense fallback={<>...</>}>
              <WorkshopUsers />
            </Suspense>
          }
        />
        <Route path='/workshop/user/add/:id'
          element={
            <Suspense fallback={<>...</>}>
              <WorkshopUserAdd />
            </Suspense>
          }
        />
        <Route path='/workshop/add'
          element={
            <Suspense fallback={<>...</>}>
              <WorkshopAdd />
            </Suspense>
          }
        />
        <Route path='/workshop/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <WorkshopAdd />
            </Suspense>
          }
        />
        <Route path='/workshop/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <WorkshopSEO />
            </Suspense>
          }
        />
        <Route path='/article-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleCategoryList />
            </Suspense>
          }
        />
        <Route path='/article-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/article-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/article-category/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleCategorySEO />
            </Suspense>
          }
        />

        <Route path='/check-user-plan/list'
          element={
            <Suspense fallback={<>...</>}>
              <CheckUserPlanList />
            </Suspense>
          }
        />

        <Route path='/check-payment/list'
          element={
            <Suspense fallback={<>...</>}>
              <CheckPaymentList />
            </Suspense>
          }
        />

        <Route path='/payment/list'
          element={
            <Suspense fallback={<>...</>}>
              <PaymentList />
            </Suspense>
          }
        />

        <Route path='/payment-collection/list'
          element={
            <Suspense fallback={<>...</>}>
              <PaymentCollectionList />
            </Suspense>
          }
        />

        <Route
          path='/payment-collection/add'
          element={
            <Suspense fallback={<>...</>}>
              <PaymentCollectionAdd />
            </Suspense>
          }
        />

        <Route
          path='/payment-collection/list'
          element={
            <Suspense fallback={<>...</>}>
              <ManualCartList />
            </Suspense>
          }
        />

        <Route path='/student-ambassador/list'
          element={
            <Suspense fallback={<>...</>}>
              <StudentAmbassadorTypes />
            </Suspense>
          }
        />
        <Route path='/student-ambassador/students'
          element={
            <Suspense fallback={<>...</>}>
              <StudentAmbassadorList />
            </Suspense>
          }
        />
        <Route path='/student-ambassador/report'
          element={
            <Suspense fallback={<>...</>}>
              <StudentAmbassadorReport />
            </Suspense>
          }
        />
        <Route path='/student-ambassador/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <StudentAmbassadorView />
            </Suspense>
          }
        />
        <Route path='/student-ambassador/user-registration/:id'
          element={
            <Suspense fallback={<>...</>}>
              <StudentRegistrationList />
            </Suspense>
          }
        />
        <Route path='/student-ambassador/users/:year/:month/:id'
          element={
            <Suspense fallback={<>...</>}>
              <StudentRegistrationDetails />
            </Suspense>
          }
        />
        <Route path='/student-ambassador/user/:year/:month/:affiliate_id/:id'
          element={
            <Suspense fallback={<>...</>}>
              <StudentUser />
            </Suspense>
          }
        />
        <Route path='/affiliate-sale/list'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateSaleType />
            </Suspense>
          }
        />
        <Route path='/affiliate-sale/sales-report'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateSaleReportList />
            </Suspense>
          }
        />
        <Route path='/affiliate-sale/salelist/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateSaleCommission />
            </Suspense>
          }
        />
        <Route path='/affiliate-sale/userlist/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateSaleUserList />
            </Suspense>
          }
        />
        <Route path='/affiliate-sale/mis-report'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateSaleMisReport />
            </Suspense>
          }
        />
        <Route path='/affiliate-sale/genuine-report'
          element={
            <Suspense fallback={<>...</>}>
              <AffiliateGenuineReport />
            </Suspense>
          }
        />
        <Route path='/faq-help/list'
          element={
            <Suspense fallback={<>...</>}>
              <FaqHelpList />
            </Suspense>
          }
        />
        <Route path='/faq-help/add'
          element={
            <Suspense fallback={<>...</>}>
              <FaqHelpAdd />
            </Suspense>
          }
        />
        <Route path='/faq-help/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <FaqHelpAdd />
            </Suspense>
          }
        />
        <Route path='/nse-certificate/list'
          element={
            <Suspense fallback={<>...</>}>
              <NseCertificateType />
            </Suspense>
          }
        />
        <Route path='/nse-certificate/add'
          element={
            <Suspense fallback={<>...</>}>
              <Navigate to="/nse-certificate/course/add" />
            </Suspense>
          }
        />
        <Route path='/nse-certificate/request'
          element={
            <Suspense fallback={<>...</>}>
              <NseCertificateRequestList />
            </Suspense>
          }
        />
        <Route path='/nse-certificate/course'
          element={
            <Suspense fallback={<>...</>}>
              <NseCertifiedCourseList />
            </Suspense>
          }
        />
        <Route path='/nse-certificate/course/add'
          element={
            <Suspense fallback={<>...</>}>
              <NseCertifiedCourseAdd />
            </Suspense>
          }
        />
        <Route path='/nse-certificate/course/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <NseCertifiedCourseAdd />
            </Suspense>
          }
        />
        <Route path='/nse-approval/list'
          element={
            <Suspense fallback={<>...</>}>
              <NseApprovalList />
            </Suspense>
          }
        />

        <Route path='/registration-block-domain/list'
          element={
            <Suspense fallback={<>...</>}>
              <BlockDomainList />
            </Suspense>
          }
        />
        <Route path='/registration-block-domain/add'
          element={
            <Suspense fallback={<>...</>}>
              <BlockDomainAdd />
            </Suspense>
          }
        />
        <Route
          path='/job/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <JobList />
            </Suspense>
          }
        />
        <Route
          path='/job/add'
          element={
            <Suspense fallback={<>Loading</>}>
              <JobAdd />
            </Suspense>
          }
        />
        <Route
          path='/job/edit/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <JobAdd />
            </Suspense>
          }
        />
        <Route
          path='/job/seo/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <JobSEO />
            </Suspense>
          }
        />
        <Route
          path='/job/tag/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <JobTag />
            </Suspense>
          }
        />
        <Route
          path='/job/application/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <JobApplication />
            </Suspense>
          }
        />
        <Route
          path='/course-region/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseRegionList />
            </Suspense>
          }
        />
        <Route
          path='/course-region/add'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseRegionAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-region/edit/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseRegionAdd />
            </Suspense>
          }
        />
        <Route
          path='/course-region/seo/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <CourseRegionSEO />
            </Suspense>
          }
        />
        <Route
          path='/slider/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <SliderList />
            </Suspense>
          }
        />
        <Route
          path='/slider/add'
          element={
            <Suspense fallback={<>Loading</>}>
              <SliderAdd />
            </Suspense>
          }
        />
        <Route
          path='/slider/edit/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <SliderAdd />
            </Suspense>
          }
        />
        <Route
          path='/coupon-report/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <CouponReportTypes />
            </Suspense>
          }
        />
        <Route
          path='/coupon-report/details'
          element={
            <Suspense fallback={<>Loading</>}>
              <CouponReportList />
            </Suspense>
          }
        />
        <Route
          path='/coupon-report/total-use'
          element={
            <Suspense fallback={<>Loading</>}>
              <CouponReportUsage />
            </Suspense>
          }
        />
        <Route
          path='/invoice/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <InvoiceList />
            </Suspense>
          }
        />
        <Route
          path='/invoice/view/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <InvoiceView />
            </Suspense>
          }
        />
        <Route
          path='/author-sale/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <AuthorSaleList />
            </Suspense>
          }
        />
        <Route
          path='/mis-report/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <MisReportList />
            </Suspense>
          }
        />
        <Route
          path='/mis-learning/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <MisLearningList />
            </Suspense>
          }
        />
        <Route
          path='/mis-learning/view/:item_type/:id/:user_id'
          element={
            <Suspense fallback={<>Loading</>}>
              <MisLearningView />
            </Suspense>
          }
        />
        <Route
          path='/manual-access-approval/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <AccessApprovalList />
            </Suspense>
          }
        />
        <Route
          path='/manual-access-approval/edit/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <AccessApprovalEdit />
            </Suspense>
          }
        />
        <Route
          path='/ncdex-approval/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <NcdexApprovalList />
            </Suspense>
          }
        />
        <Route path='/ncdex-certificate/list'
          element={
            <Suspense fallback={<>...</>}>
              <NcdexCertificateType />
            </Suspense>
          }
        />
        <Route path='/ncdex-certificate/request'
          element={
            <Suspense fallback={<>...</>}>
              <NcdexCertificateRequestList />
            </Suspense>
          }
        />
        <Route path='/ncdex-certificate/course'
          element={
            <Suspense fallback={<>...</>}>
              <NcdexCertifiedCourseList />
            </Suspense>
          }
        />
        <Route path='/ncdex-certificate/add'
          element={<Navigate to="/ncdex-certificate/course/add" />}
        />
        <Route path='/ncdex-certificate/course/add'
          element={
            <Suspense fallback={<>...</>}>
              <NcdexCertifiedCourseAdd />
            </Suspense>
          }
        />
        <Route path='/ncdex-certificate/course/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <NcdexCertifiedCourseAdd />
            </Suspense>
          }
        />
        <Route path='/bank-interest-rate/list'
          element={
            <Suspense fallback={<>...</>}>
              <BankInterestRateList />
            </Suspense>
          }
        />
        <Route path='/bank-interest-rate/add'
          element={
            <Suspense fallback={<>...</>}>
              <BankInterestRateAdd />
            </Suspense>
          }
        />
        <Route path='/bank-interest-rate/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <BankInterestRateAdd />
            </Suspense>
          }
        />
        <Route path='/author-request/list'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorRequestList />
            </Suspense>
          }
        />
        <Route path='/author-request/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorRequestEdit />
            </Suspense>
          }
        />
        <Route
          path='/facetoface-author/list'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceAuthorList />
            </Suspense>
          }
        />
        <Route path='/facetoface-author/add'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceAuthorAdd />
            </Suspense>
          }
        />
        <Route path='/facetoface-author/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <Face2FaceAuthorAdd />
            </Suspense>
          }
        />
        <Route path='/stockedge-club-user-detail/list'
          element={
            <Suspense fallback={<>...</>}>
              <StockedgeClubUserDetailList />
            </Suspense>
          }
        />
        <Route path='/stockedge-club-user-detail/view/:id'
          element={
            <Suspense fallback={<>...</>}>
              <StockedgeClubUserDetail />
            </Suspense>
          }
        />
        <Route path='/partner-coupon/list'
          element={
            <Suspense fallback={<>...</>}>
              <PartnerCouponLogList />
            </Suspense>
          }
        />
        <Route path='/partner-coupon/import'
          element={
            <Suspense fallback={<>...</>}>
              <PartnerCouponImport />
            </Suspense>
          }
        />
        <Route path='/product-delete-log/list'
          element={
            <Suspense fallback={<>...</>}>
              <ProductDeleteLogPage />
            </Suspense>
          }
        />
        <Route path='/product-delete-log/:type/list'
          element={
            <Suspense fallback={<>...</>}>
              <ProductDeleteLogList />
            </Suspense>
          }
        />
        <Route path='/product-delete-log/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ProductDeleteLogEdit />
            </Suspense>
          }
        />
        <Route path='/manual-access-request/list'
          element={
            <Suspense fallback={<>...</>}>
              <ManualAccessRequestList />
            </Suspense>
          }
        />
        <Route path='/manual-access-request/add'
          element={
            <Suspense fallback={<>...</>}>
              <ManualAccessRequestAdd />
            </Suspense>
          }
        />
        <Route path='/manual-course-access/list'
          element={
            <Suspense fallback={<>...</>}>
              <ManualCourseAccessList />
            </Suspense>
          }
        />
        <Route path='/manual-course-access/add'
          element={
            <Suspense fallback={<>...</>}>
              <ManualCourseAccessAdd />
            </Suspense>
          }
        />
        <Route path='/manual-plan-access/list'
          element={
            <Suspense fallback={<>...</>}>
              <ManualPlanAccessList />
            </Suspense>
          }
        />
        <Route path='/manual-plan-access/add'
          element={
            <Suspense fallback={<>...</>}>
              <ManualPlanAccessAdd />
            </Suspense>
          }
        />
        <Route path='/author/list'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorList />
            </Suspense>
          }
        />
        <Route path='/author/add'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorExistingUserAdd />
            </Suspense>
          }
        />
        <Route path='/author/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorEdit />
            </Suspense>
          }
        />
        <Route path='/author/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorSEO />
            </Suspense>
          }
        />
        <Route path='/author/commission/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorCommission />
            </Suspense>
          }
        />
        <Route path='/author-commission-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorCommissionList />
            </Suspense>
          }
        />
        <Route path='/author-commission-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorCommissionAdd />
            </Suspense>
          }
        />
        <Route path='/author-commission-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorCommissionAdd />
            </Suspense>
          }
        />
        <Route path='/author-commission-category/items/:id'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorCommissionItems />
            </Suspense>
          }
        />
        <Route path='/article/list'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleList />
            </Suspense>
          }
        />
        <Route path='/article/add'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleAdd />
            </Suspense>
          }
        />
        <Route path='/article/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleAdd />
            </Suspense>
          }
        />
        <Route path='/article/seo/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleSEO />
            </Suspense>
          }
        />
        <Route path='/article/units/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleUnitList />
            </Suspense>
          }
        />
        <Route path='/article/unit/add/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleUnitAdd />
            </Suspense>
          }
        />
        <Route path='/article/unit/edit/:id/:setting_id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleUnitAdd />
            </Suspense>
          }
        />
        <Route path='/article/unit/seo/:id/:setting_id'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleUnitSEO />
            </Suspense>
          }
        />
        <Route path='/article/analytics'
          element={
            <Suspense fallback={<>...</>}>
              <ArticleAnalytic />
            </Suspense>
          }
        />
        <Route path='/author-report/list'
          element={
            <Suspense fallback={<>...</>}>
              <AuthorReportList />
            </Suspense>
          }
        />
        <Route path='/module-category/list'
          element={
            <Suspense fallback={<>...</>}>
              <ModuleCategoryList />
            </Suspense>
          }
        />
        <Route path='/module-category/add'
          element={
            <Suspense fallback={<>...</>}>
              <ModuleCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/module-category/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <ModuleCategoryAdd />
            </Suspense>
          }
        />
        <Route path='/webinar-analytics/list'
          element={
            <Suspense fallback={<>...</>}>
              <WebinarAnalyticsList />
            </Suspense>
          }
        />
        <Route path='/sitemap/list'
          element={
            <Suspense fallback={<>...</>}>
              <SiteMap />
            </Suspense>
          }
        />
        <Route path='/course-caption-setting/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseCaptionList />
            </Suspense>
          }
        />
        <Route path='/course-caption-setting/add'
          element={
            <Suspense fallback={<>...</>}>
              <CourseCaptionAdd />
            </Suspense>
          }
        />
        <Route path='/course-caption-setting/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <CourseCaptionAdd />
            </Suspense>
          }
        />
        <Route path='/course-completion-percentage-bulk-email/list'
          element={
            <Suspense fallback={<>...</>}>
              <CourseCompletionBulkEmailList />
            </Suspense>
          }
        />
        <Route path='/unsubscribe/list'
          element={
            <Suspense fallback={<>...</>}>
              <UnsubscribeList />
            </Suspense>
          }
        />
        <Route path='/all-in-one-certificate/list'
          element={
            <Suspense fallback={<>...</>}>
              <AllInOneCertificateList />
            </Suspense>
          }
        />
        <Route path='/webinar-caption-setting/list'
          element={
            <Suspense fallback={<>...</>}>
              <WebinarCaptionList />
            </Suspense>
          }
        />
        <Route path='/webinar-caption-setting/add'
          element={
            <Suspense fallback={<>...</>}>
              <WebinarCaptionAdd />
            </Suspense>
          }
        />
        <Route path='/webinar-caption-setting/edit/:id'
          element={
            <Suspense fallback={<>...</>}>
              <WebinarCaptionAdd />
            </Suspense>
          }
        />
        <Route path='/popup-offer/list'
          element={
            <Suspense fallback={<>Loading</>}>
              <PopupOfferList />
            </Suspense>
          }
        />
        <Route path='/popup-offer/add'
          element={
            <Suspense fallback={<>Loading</>}>
              <PopupOfferListAdd />
            </Suspense>
          }
        />
        <Route path='/popup-offer/edit/:id'
          element={
            <Suspense fallback={<>Loading</>}>
              <PopupOfferListAdd />
            </Suspense>
          }
        />
        <Route path='/user-plan/list'
          element={
            <Suspense fallback={<>...</>}>
              <UserPlanList />
            </Suspense>
          }
        />
        <Route path='/deferred-report/list'
          element={
            <Suspense fallback={<>...</>}>
              <DeferredReport />
            </Suspense>
          }
        />
        <Route path='/user-mis/list'
          element={
            <Suspense fallback={<>...</>}>
              <UserMisList />
            </Suspense>
          }
        />
      </Route>

      <Route path='*' element={<Error />} />
    </Routes>
  )
}

export default RouteMain