import React from 'react';

const Dashboard = () => {
	return (
		<div className='inner_right_section'>
			<div className='inner_right_content'>
				<div className='row' style={{ color: '#FFF' }}>
					<div className='col-lg-12' style={{ lineHeight: '2.5' }}>
						<div className="text-center mb-3">
							<h5 style={{ color: '#e38524' }}>
								This is the dashboard of Content Management System of {" "}
								<small>Elearnmarkets</small>
							</h5>
						</div>
						<p style={{ lineHeight: '10px' }}>Please note the following things :</p>
						<ol style={{ fontSize: '14px' }}>
							<li>You will only see things that you have given access into.</li>
							<li>Your every action is monitored and managed automatically.</li>
						</ol>

						<small>
							Note: For any issues / suggestions mail to sanjib@stockedge.com
						</small>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
